import { SSOTokenType } from "@operations-hero/lib-api-client";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../components/auth/AuthProvider";
import { useProductSubscriptions } from "../components/shell/AppShell";
import { RootState } from "../store";
import { useShowToast } from "./showToast";
export type NavItem = {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
  to?: string;
  Element?: (params: { onClick: () => void }) => JSX.Element;
};

export const useNavProductItems = () => {
  const {
    apiClient,
    isProductStandard,
    isProductAdmin,
    currentAccount,
    isEventAdmin,
    isEventStandard,
    isInventoryStandard,
    isInventoryAdmin,
    isEnergyUser,
    isPlanningAdmin,
  } = useAuthentication();
  const { policyMap } = useSelector((state: RootState) => state.localCache);
  const { allowInternalUserSubmitEvents } = useSelector(
    (state: RootState) => state.eventSettingsSlice
  );
  const { hasRequests, hasEvents, hasInventory, hasEnergy, hasPlanning } =
    useProductSubscriptions();

  const showToast = useShowToast();

  const energyCallback = useCallback(() => {
    apiClient
      .getSsoToken(currentAccount.id, SSOTokenType.energy)
      .then((response) => {
        const { jwt } = response;
        window.open(
          `${process.env.REACT_APP_NIMBLE_ENERGY_SSO_URL}?jwt=${jwt}`,
          "_blank"
        );
      })
      .catch(() => {
        showToast("error", "Something went wrong. Please try again later.");
      });
  }, [apiClient, currentAccount.id, showToast]);

  const navItems: NavItem[] = useMemo(() => {
    const items = [];
    if (
      Object.keys(policyMap).length > 0 &&
      hasRequests &&
      (isProductAdmin || isProductStandard)
    ) {
      items.push({
        label: "Requests",
        to: "/requests",
      });
    }

    if (
      hasEvents &&
      (allowInternalUserSubmitEvents || isEventAdmin || isEventStandard)
    ) {
      items.unshift({
        label: "Events",
        to: "/events",
      });
    }

    if (hasInventory && (isInventoryStandard || isInventoryAdmin)) {
      items.push({
        label: "Inventory",
        to: isInventoryAdmin ? "/inventory/items" : "/inventory/requests",
      });
    }

    if (hasEnergy && isEnergyUser) {
      items.push({ label: "Energy" });
    }

    if (hasPlanning && isPlanningAdmin) {
      items.push({ label: "Planning", to: "/planning" });
    }

    return items;
  }, [
    allowInternalUserSubmitEvents,
    hasEnergy,
    hasEvents,
    hasInventory,
    hasPlanning,
    hasRequests,
    isEnergyUser,
    isEventAdmin,
    isEventStandard,
    isInventoryAdmin,
    isInventoryStandard,
    isPlanningAdmin,
    isProductAdmin,
    isProductStandard,
    policyMap,
  ]);

  return { navItems, energyCallback };
};
