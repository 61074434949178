import { Box } from "@chakra-ui/react";
import { ApiAssetCondition } from "@operations-hero/lib-api-client";
import { useMemo } from "react";
import { GroupBase, SingleValueProps } from "react-select";
import { AssetConditionBadge } from "../badges/AssetConditionBadge";
import { createOptionComponent } from "./select-overrides";
import { StyledSelect } from "./StyledSelect";

export interface AssetConditionSelectProps {
  value: ApiAssetCondition;
  onChange?: (status: ApiAssetCondition) => void;
  isDisabled?: boolean;
  name?: string;
}

type ValueLabel = {
  value: ApiAssetCondition;
  label: string;
};

const CustomOptionComponent = createOptionComponent(AssetConditionBadge);

const CustomSingleValueComponent = (
  props: SingleValueProps<ValueLabel, false, GroupBase<ValueLabel>>
) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps}>
      <AssetConditionBadge value={data.value} />
    </Box>
  );
};

export const AssetConditionSelect = ({
  value,
  onChange,
  isDisabled,
  name,
}: AssetConditionSelectProps) => {
  const options = [
    ApiAssetCondition.excelent,
    ApiAssetCondition.good,
    ApiAssetCondition.fair,
    ApiAssetCondition.poor,
    ApiAssetCondition.failing,
  ].map((x) => ({
    value: x,
    label: x,
  }));

  const handleChange = (item: ValueLabel | null) => {
    if (onChange && item) onChange(item.value);
  };

  const components = useMemo(
    () => ({
      SingleValue: CustomSingleValueComponent,
      Option: CustomOptionComponent,
      DropdownIndicator: () => null,
      IndicatorSeparator: () => null,
    }),
    []
  );

  return (
    <StyledSelect
      value={options.find((x) => x.value === value)}
      options={options}
      onChange={handleChange}
      components={components}
      isDisabled={isDisabled}
      name={name}
    />
  );
};
