import { Box, Button, Icon, VStack } from "@chakra-ui/react";
import React from "react";
import { AiOutlineCamera } from "react-icons/ai";
import { Attachments } from "../../../../components/attachments/Attachments";
import Webcam from "../../../../components/webcam/webcam";

export interface InitialAssetModalProps {
  webcamRef: React.RefObject<Webcam>;
  videoConstraints: {
    width?: number;
    height?: number;
    facingMode: string;
  };
  captureNameplate: () => void;
  workingAttachments: any[];
  handleAddAttachment: (attachment: any) => void;
}

export const AiNampleplatePhotoCapture = ({
  webcamRef,
  videoConstraints,
  captureNameplate,
  workingAttachments,
  handleAddAttachment,
}: InitialAssetModalProps) => {
  return (
    <Box pb={20} pt={0}>
      <VStack width="100%" gap={".25em"} mt={0}>
        <Box
          maxH="80%"
          width="100%"
          flex={1}
          justifyContent="center"
          display="flex"
        >
          <Webcam
            width="100%"
            style={{ maxHeight: "80%" }}
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
          />
        </Box>
        <Button
          colorScheme="blue"
          aria-label="Take Picture"
          width="100%"
          justifyContent="center"
          onClick={captureNameplate}
        >
          <Icon as={AiOutlineCamera} boxSize={6} />
        </Button>
      </VStack>
      <Box borderTop="1px solid #e2e8f0" pt={4} mt={4}>
        <Attachments
          attachments={workingAttachments}
          onNewAttachments={handleAddAttachment}
          onDeleteAttachment={() => {}}
          title="Upload Picture"
          gridColumns={[2, null, 3, null, 4]}
          allowedTypes={["image/*"]}
        />
      </Box>
    </Box>
  );
};
