import { Flex, HStack } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { FC, useState } from "react";
import { useNavAllowedProductSearch } from "../../../../hooks/useNavAllowedProductSearch";
import LogoSmall from "../Logo.sm";
import { CreateMenu } from "../quick-actions/CreateMenu";
import { QRButton } from "../quick-actions/QRButton";
import { SearchAutocomplete } from "../quick-actions/SearchAutocomplete";

type NavItemsProps = {
  mediaQueries: boolean[];
};

export const NavItems: FC<NavItemsProps> = ({ mediaQueries }) => {
  const [isMobileSmall = true, isMobileMedium = false, isMobileLarge = false] =
    mediaQueries;
  const [expanded, setIsExpanded] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const { allowedProductSearch } = useNavAllowedProductSearch();

  return (
    <Flex flex={1} alignItems="center" gap={2}>
      <motion.div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          overflowY: "clip",
          height: "40px",
          alignItems: "center",
          minHeight: "60px",
          width: "100%",
        }}
        initial={false}
        animate={{
          flexBasis: expanded ? "0%" : "97%",
        }}
        transition={{
          duration: 0.2,
        }}
        onAnimationStart={() => {
          if (!expanded) {
            setShowButtons(true);
          }
        }}
      >
        <AnimatePresence
          onExitComplete={() => {
            setIsExpanded(true);
          }}
        >
          {showButtons && (
            <motion.div
              style={{ width: "100%" }}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              transition={{
                duration: 0.2,
              }}
            >
              {isMobileSmall && (
                <HStack w="full" justifyContent="space-between">
                  <LogoSmall />
                  <HStack>
                    <CreateMenu mediaQueries={[true]} />
                    <QRButton />
                  </HStack>
                </HStack>
              )}

              {isMobileMedium && (
                <HStack w="full" justifyContent="flex-end">
                  <CreateMenu mediaQueries={[false, true]} />
                  <QRButton />
                </HStack>
              )}

              {isMobileLarge && (
                <HStack w="full" justifyContent="flex-end">
                  <CreateMenu mediaQueries={[false, false, false]} />
                  <QRButton />
                </HStack>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
      <motion.div
        initial={false}
        animate={{
          flexBasis: expanded ? "100%" : "3%",
        }}
        transition={{
          duration: 0.2,
        }}
      >
        <SearchAutocomplete
          onSearchClick={() => {
            setShowButtons(false);
          }}
          allowedProductSearch={allowedProductSearch}
          isSearchBoxExpanded={expanded}
          onBlur={() => {
            setIsExpanded(false);
          }}
        />
      </motion.div>
    </Flex>
  );
};
