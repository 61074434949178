import { Box, Center, Spinner } from "@chakra-ui/react";
import { ApiAccountSubscription } from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { Country } from "country-state-city";
import {
  createContext,
  Fragment,
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import {
  matchPath,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AccountSettings } from "../../pages/account-settings/AccountSettings";
import { useDashboardRoutes } from "../../pages/account-settings/routes/dashboardRoutes";
import { useInventoryRoutes } from "../../pages/account-settings/routes/InventoryRoutes";
import { useMainAccountRoutes } from "../../pages/account-settings/routes/MainAccountRoutes";
import { useIsReviewerOrTechnicianOfWorkflows } from "../../pages/account-settings/routes/routes-helpers/policies-checks";
import { useIsAllowManageAssetForTech } from "../../pages/account-settings/routes/routes-helpers/settings-checks";
import { Announcement } from "../../pages/account-settings/system-announcement/Announcement";
import { ErrorPage } from "../../pages/error-pages/ErrorPage";
import { EventFormModal } from "../../pages/events/event-form/EventFormModal";
import { LadingPageHandler } from "../../pages/landing-page/MainLandingPage";
import { PlanningHQHome } from "../../pages/planning-hq/PlanningHQHome";
import { ProjectPage } from "../../pages/planning-hq/project/ProjectPage";
import { ReportPage } from "../../pages/reports/ReportPage";
import { RequestForm } from "../../pages/request-form/RequestForm";
import { TaskBookWorkView } from "../../pages/request-form/taskbooks/taskbook-workview/TaskbookWorkView";
import { useAppShellStylingContext } from "../../pages/requests/column-view/AppShellStylingContext";
import { Requests } from "../../pages/requests/Requests";
import { Timesheet } from "../../pages/timesheet/Timesheet";
import { Transactions } from "../../pages/transactions/Transactions";
import { UserProfilePage } from "../../pages/user-profile/UserProfilePage";
import { RootState, useThunkDispatch } from "../../store";
import { setCurrentUser } from "../../store/auth.slice";
import { initEventSettings } from "../../store/event-settings.slice";
import { setCountry } from "../../store/globals.slice";
import { initLocalCache, setErrorLoading } from "../../store/local-cache.slice";
import { setNewRequestDialogIsOpen } from "../../store/new-request-form.slice";
import { useAuthentication } from "../auth/AuthProvider";
import { FallbackSpinner } from "../code-splitting/FallbackSpinner";
import { FirstRunWizard } from "../first-run-wizard/FirstRunWizard";
import { NewRequestModal } from "../new-request-form/NewRequestModal";
import { QRCodeLookup } from "../qr-code-lookup/QRCodeLookup";
import { AppAlert, SubscriptionAlert, WillExpireSoonAlert } from "./AppAlert";
import { NavBar } from "./nav/NavBar";

export interface SubscriptionContextInterface {
  hasEvents: boolean;
  hasRequests: boolean;
  hasInventory: boolean;
  hasBuildingAutomationIntegration: boolean;
  hasEnergy: boolean;
  hasHeroText: boolean;
  hasPlanning: boolean;
}
const ProductSubscriptionsContext = createContext<SubscriptionContextInterface>(
  {} as SubscriptionContextInterface
);

export const useProductSubscriptions = () =>
  useContext(ProductSubscriptionsContext);

const HERO_HQ = "HeroHQ";
const EVENTS = "Events";
const INVENTORY = "InventoryHQ";
const BUILDING_AUTOMATION_INTEGRATION = "BuildingAutomationIntegration";
const ENERGY_HQ = "EnergyHQ";
const HERO_TEXT = "HeroTEXT";
const PLANNING_HQ = "PlanningHQ";

const EventList = lazy(
  () => import("../../pages/events/events-list/EventList")
);
const EventDetails = lazy(
  () => import("../../pages/events/event-details/EventDetails")
);
const CalendarPage = lazy(() => import("../../pages/calendar/CalendarPage"));
const InventorySettings = lazy(
  () => import("../../pages/inventory/InventorySettings")
);

export default function AppShell() {
  const { styling } = useAppShellStylingContext();
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const {
    apiClient,
    currentAccount,
    currentUser,
    isProductStandard,
    isProductAdmin,
    isEventAdmin,
    isEventStandard,
    isInventoryStandard,
    isInventoryAdmin,
    isPlanningAdmin,
  } = useAuthentication();
  const location = useLocation();
  const navigate = useNavigate();
  // Saving location history prev and current
  const locationHistory = useRef<string[]>(["", ""]);

  const {
    subscriptions,
    isAdmin,
    isReviewer,
    isTechnician,
    isContractor,
    policyMap,
    policyAllowsDashboard,
    errorLoading,
    isVenueManager,
  } = useSelector((state: RootState) => state.localCache);

  const {
    allowInternalUserSubmitEvents,
    allowInternalUsersViewCalendar,
    isLoading: eventsIsLoading,
  } = useSelector((state: RootState) => state.eventSettingsSlice);

  const [loading, setLoading] = useState(true);
  const [noAccountWarning, setNoAccountWarning] = useState(false);

  const { isReviewerOrTech } = useIsReviewerOrTechnicianOfWorkflows();
  const { isAllowManageAssetForTech } = useIsAllowManageAssetForTech();

  const enableTechRoutes = useMemo(
    () => isReviewerOrTech && isAllowManageAssetForTech,
    [isReviewerOrTech, isAllowManageAssetForTech]
  );

  const { isModalOpen } = useSelector(
    (state: RootState) => state.newEventSlice
  );

  const errorHandlerGoBack = useCallback(() => {
    if (
      locationHistory.current.length > 0 &&
      locationHistory.current[0] &&
      locationHistory.current[0] !== location.pathname
    ) {
      navigate(locationHistory.current[0]);
    } else {
      navigate("/");
    }
  }, [navigate, location.pathname]);

  const showTransactions = useMemo(
    () => policyAllowsDashboard || isProductAdmin || isAdmin || isReviewer,
    [policyAllowsDashboard, isProductAdmin, isAdmin, isReviewer]
  );

  const showTimesheet = useMemo(
    () =>
      isProductAdmin || isAdmin || isReviewer || isContractor || isTechnician,
    [isProductAdmin, isAdmin, isReviewer, isContractor, isTechnician]
  );

  const showCalendar = useMemo(
    () =>
      isProductAdmin ||
      isAdmin ||
      isReviewer ||
      isTechnician ||
      isEventAdmin ||
      isEventStandard ||
      allowInternalUsersViewCalendar,
    [
      isProductAdmin,
      isAdmin,
      isReviewer,
      isTechnician,
      isEventAdmin,
      isEventStandard,
      allowInternalUsersViewCalendar,
    ]
  );

  const heroHQ = useMemo(
    () => subscriptions.find((sub) => sub.product.name === HERO_HQ),
    [subscriptions]
  );

  const events = useMemo(
    () => subscriptions.find((sub) => sub.product.name === EVENTS),
    [subscriptions]
  );

  const inventory = useMemo(
    () => subscriptions.find((sub) => sub.product.name === INVENTORY),
    [subscriptions]
  );

  const buildingAutomationIntegration = useMemo(
    () =>
      subscriptions.find(
        (sub) => sub.product.name === BUILDING_AUTOMATION_INTEGRATION
      ),
    [subscriptions]
  );

  const energyHQ = useMemo(
    () => subscriptions.find((sub) => sub.product.name === ENERGY_HQ),
    [subscriptions]
  );

  const heroText = useMemo(
    () => subscriptions.find((sub) => sub.product.name === HERO_TEXT),
    [subscriptions]
  );

  const planning = useMemo(
    () => subscriptions.find((sub) => sub.product.name === PLANNING_HQ),
    [subscriptions]
  );

  const isExpired = useCallback((subscription?: ApiAccountSubscription) => {
    if (!subscription) {
      return true;
    }
    const now = new Date().getTime();
    const expires = new Date(subscription.expires).getTime();
    return now > expires;
  }, []);

  const productsSubscriptions = useMemo(() => {
    return {
      hasRequests: !isExpired(heroHQ),
      hasEvents: !isExpired(events),
      hasInventory: !isExpired(inventory),
      hasBuildingAutomationIntegration: !isExpired(
        buildingAutomationIntegration
      ),
      hasEnergy: !isExpired(energyHQ),
      hasHeroText: !isExpired(heroText),
      hasPlanning: !isExpired(planning),
    };
  }, [
    events,
    heroHQ,
    inventory,
    isExpired,
    heroText,
    energyHQ,
    buildingAutomationIntegration,
    planning,
  ]);

  const noProducts = useMemo(
    () => Object.values(productsSubscriptions).every((prod) => prod === false),
    [productsSubscriptions]
  );

  const emailNotVerified = useMemo(
    () => currentUser.emailVerified === null,
    [currentUser]
  );

  const hasProducts = useMemo(() => {
    if (
      productsSubscriptions.hasEvents &&
      (isEventAdmin || isEventStandard || allowInternalUserSubmitEvents)
    ) {
      return true;
    }
    if (
      productsSubscriptions.hasRequests &&
      (isProductAdmin || isProductStandard)
    ) {
      return true;
    }
    if (
      productsSubscriptions.hasInventory &&
      (isInventoryStandard || isInventoryAdmin)
    ) {
      return true;
    }
    if (productsSubscriptions.hasBuildingAutomationIntegration) {
      return true;
    }

    if (productsSubscriptions.hasPlanning) {
      return true;
    }

    return false;
  }, [
    productsSubscriptions,
    allowInternalUserSubmitEvents,
    isEventAdmin,
    isEventStandard,
    isProductAdmin,
    isProductStandard,
    isInventoryStandard,
    isInventoryAdmin,
  ]);

  const hasRequestAccess = useMemo(() => {
    return (
      Object.keys(policyMap).length > 0 &&
      productsSubscriptions.hasRequests &&
      (isProductAdmin || isProductStandard)
    );
  }, [
    isProductAdmin,
    isProductStandard,
    productsSubscriptions.hasRequests,
    policyMap,
  ]);

  const hasEventAccess = useMemo(() => {
    return (
      productsSubscriptions.hasEvents &&
      (isEventAdmin || isEventStandard || allowInternalUserSubmitEvents)
    );
  }, [
    productsSubscriptions.hasEvents,
    allowInternalUserSubmitEvents,
    isEventAdmin,
    isEventStandard,
  ]);

  const hasInventoryAccess = useMemo(() => {
    return (
      productsSubscriptions.hasInventory &&
      (isInventoryAdmin || isInventoryStandard)
    );
  }, [
    productsSubscriptions.hasInventory,
    isInventoryStandard,
    isInventoryAdmin,
  ]);

  const hasPlanningAccess = useMemo(() => {
    return productsSubscriptions.hasPlanning && isPlanningAdmin;
  }, [productsSubscriptions.hasPlanning, isPlanningAdmin]);

  const isNotAllowed = useMemo(() => {
    const isRequestPath = matchPath(location.pathname, "/requests");
    const isEventsPath = matchPath(location.pathname, "/events");
    const isInventoryPath = matchPath(location.pathname, "/inventory");
    const isPlanningPath = matchPath(location.pathname, "/planning");

    if (isRequestPath && !hasRequestAccess) return true;
    if (isEventsPath && !hasEventAccess) return true;
    if (isInventoryPath && !hasInventoryAccess) return true;
    if (isPlanningPath && !hasPlanningAccess) return true;

    return false;
  }, [
    location,
    hasRequestAccess,
    hasEventAccess,
    hasInventoryAccess,
    hasPlanningAccess,
  ]);

  const defaultRedirectionView = useMemo(() => {
    if (hasRequestAccess) {
      return "/requests";
    }
    if (hasEventAccess) {
      return "/events";
    }
    if (hasInventoryAccess) {
      if (isInventoryAdmin) {
        return "/inventory";
      }
      if (isInventoryStandard) {
        return "/inventory/requests";
      }
    }
    if (hasPlanningAccess) {
      return "/planning";
    }
    //neither access product will show no product warning and this route is not rendered
    return "/requests";
  }, [
    hasRequestAccess,
    hasEventAccess,
    hasInventoryAccess,
    hasPlanningAccess,
    isInventoryAdmin,
    isInventoryStandard,
  ]);

  const setAccountCountry = useCallback(() => {
    if (!currentAccount) return;

    apiClient.getAccountDetail(currentAccount.id).then((detail) => {
      const country = Country.getCountryByCode(detail.address.country || "");
      const defaultCountry = { name: "United States", isoCode: "US" };
      const newCountry = country
        ? { name: country.name, isoCode: country.isoCode }
        : defaultCountry;
      dispatch(setCountry(newCountry));
    });
  }, [apiClient, currentAccount, dispatch]);

  useEffect(() => {
    if (!currentAccount || !currentAccount.id) {
      setNoAccountWarning(true);
      setLoading(false);
      return;
    }

    setAccountCountry();
    thunkDispatch(
      initLocalCache({
        apiClient,
        account: currentAccount,
        user: currentUser,
      })
    )
      .then(unwrapResult)
      .then((res) => {
        if (res.userUpdated) {
          dispatch(setCurrentUser(res.userUpdated));
        }
      })
      .then(
        () => {},
        (err) => {
          console.log("loading error local-cache", err);
          dispatch(setErrorLoading(true));
        }
      )
      .finally(() => {
        setLoading(false);
      });
    // not included currentAccount and currentUser deps, are generating infinity loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClient, dispatch, setAccountCountry, thunkDispatch]);

  // Load event settings effect
  useEffect(() => {
    if (!productsSubscriptions.hasEvents) {
      return;
    }
    thunkDispatch(
      initEventSettings({ apiClient, accountId: currentAccount.id })
    );
  }, [
    productsSubscriptions.hasEvents,
    apiClient,
    currentAccount,
    thunkDispatch,
  ]);

  useEffect(() => {
    if (loading) {
      return;
    }
    // prevents navigation to account settings area unless the user has permissions
    if (
      location.pathname.includes("account") &&
      !isProductAdmin &&
      !isEventAdmin &&
      !isVenueManager &&
      !isInventoryAdmin &&
      !enableTechRoutes
    ) {
      navigate(defaultRedirectionView, { replace: true });
      return;
    }

    // handles new request deeplink
    const query = new URLSearchParams(window.location.search);
    const newRequest = query.get("newRequest");

    if (newRequest === "true") {
      const workflowSlug = query.get("workflow");
      if (workflowSlug) {
        localStorage.setItem(
          `${currentAccount.id}:new-request-wf`,
          workflowSlug
        );
      }
      dispatch(setNewRequestDialogIsOpen(true));
      navigate(location.pathname, { replace: true });
      return;
    }

    // prevent navigation to dashboard unless the user has permissions
    if (
      location.pathname === "/" &&
      !policyAllowsDashboard &&
      !isProductAdmin
    ) {
      navigate(defaultRedirectionView, { replace: true });
      return;
    }

    // Saving the previous location.
    // When the app throws an unhandled error. Having this value will help us to return the previous valid location
    if (locationHistory.current[1] !== location.pathname) {
      locationHistory.current[0] = locationHistory.current[1];
      locationHistory.current[1] = location.pathname;
    }

    // enjoy the next route user
  }, [
    dispatch,
    currentAccount,
    loading,
    policyAllowsDashboard,
    isProductAdmin,
    isEventAdmin,
    isInventoryAdmin,
    navigate,
    defaultRedirectionView,
    isVenueManager,
    location.pathname,
    enableTechRoutes,
  ]);

  const subscriptionsArray = useMemo(() => {
    if (!events && !heroHQ && !inventory) return;
    const result = [];
    events && result.push(events);
    heroHQ && result.push(heroHQ);
    inventory && result.push(inventory);
    planning && result.push(planning);

    return result;
  }, [events, heroHQ, inventory, planning]);

  const { accountRoutes } = useMainAccountRoutes(productsSubscriptions);
  const { routes: dashboardRoutes } = useDashboardRoutes();
  const { routes: inventoryRoutes } = useInventoryRoutes(
    productsSubscriptions.hasInventory
  );

  return (
    <Box sx={styling}>
      <ErrorBoundary FallbackComponent={ErrorPage} onReset={errorHandlerGoBack}>
        <ProductSubscriptionsContext.Provider
          value={{ ...productsSubscriptions }}
        >
          <NavBar />
          {loading && (
            <Center>
              <Spinner m={10} color="blue.500" />
            </Center>
          )}

          {!loading && errorLoading && <AppAlert alertType="errorLoading" />}

          {!loading && !errorLoading && noProducts && (
            <AppAlert alertType="noProducts" />
          )}

          {!loading && !errorLoading && !noProducts && !hasProducts && (
            <AppAlert alertType="noAccountWarning" />
          )}

          {!loading && !errorLoading && !noAccountWarning && (
            <SubscriptionAlert subscription={heroHQ} name={HERO_HQ} />
          )}

          {!loading && !errorLoading && !noAccountWarning && (
            <SubscriptionAlert subscription={events} name={EVENTS} />
          )}
          {!loading && !errorLoading && !noAccountWarning && (
            <WillExpireSoonAlert subscriptions={subscriptionsArray} />
          )}

          {!loading && !errorLoading && emailNotVerified && (
            <AppAlert alertType="noEmailVerified" status="warning" />
          )}

          {!loading &&
            (!productsSubscriptions.hasEvents || !eventsIsLoading) &&
            !errorLoading &&
            isNotAllowed &&
            hasProducts && <AppAlert alertType="notAllowed" />}

          {!loading && !errorLoading && !noAccountWarning && hasProducts && (
            <>
              <Announcement />

              <Box as="main">
                <Suspense fallback={<FallbackSpinner />}>
                  <Routes>
                    <Route path="/" element={<LadingPageHandler />} />
                    <Route path="/dashboard" element={<LadingPageHandler />} />
                    {dashboardRoutes}
                    <Route
                      path="/user-settings"
                      element={<UserProfilePage />}
                    />
                    <Route path="/account" element={<AccountSettings />}>
                      {accountRoutes}
                    </Route>

                    {showTransactions && (
                      <Route path="/transactions" element={<Transactions />} />
                    )}
                    {showTimesheet && (
                      <Route path="/timesheet" element={<Timesheet />} />
                    )}

                    {showCalendar && (
                      <Route path="/calendar" element={<CalendarPage />} />
                    )}

                    {hasRequestAccess && (
                      <Fragment>
                        <Route path="/requests" element={<Requests />} />
                        <Route
                          path="/requests/:key"
                          element={<RequestForm />}
                        />
                        <Route
                          path="/requests/:key/books/:requestTaskbookId/mode/:readOnly"
                          element={<TaskBookWorkView />}
                        />
                        <Route
                          path="/requests/:key/books/:requestTaskbookId"
                          element={<TaskBookWorkView />}
                        />
                      </Fragment>
                    )}

                    {hasEventAccess && (
                      <Fragment>
                        <Route path="/events" element={<EventList />} />
                        <Route
                          path="/events/:idOrKey"
                          element={<EventDetails />}
                        />
                      </Fragment>
                    )}

                    {hasInventoryAccess && (
                      <Route path="/inventory" element={<InventorySettings />}>
                        {inventoryRoutes}
                      </Route>
                    )}
                    {(hasPlanningAccess || hasRequestAccess) && (
                      <>
                        {hasPlanningAccess ? (
                          <Route
                            path="/planning/:section?"
                            element={<PlanningHQHome />}
                          />
                        ) : (
                          <Route
                            path="/planning/projects"
                            element={<PlanningHQHome />}
                          />
                        )}
                        <Route
                          path="/planning/projects/:id/:section?"
                          element={<ProjectPage />}
                        />
                      </>
                    )}

                    {(isEventAdmin || isInventoryAdmin || isProductAdmin) && (
                      <Fragment>
                        <Route
                          path="/reports/:product?/:id?"
                          element={<ReportPage />}
                        />
                      </Fragment>
                    )}
                  </Routes>
                </Suspense>
                <FirstRunWizard />
                <NewRequestModal />
                {isModalOpen && <EventFormModal />}
                <QRCodeLookup />
              </Box>
            </>
          )}
        </ProductSubscriptionsContext.Provider>
      </ErrorBoundary>
    </Box>
  );
}
