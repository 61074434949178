import { ChevronDownIcon, SmallAddIcon } from "@chakra-ui/icons";
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { FC, Fragment, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../store";
import { setIsOpenEventModal } from "../../../../store/events/event-modal.slice";
import { setNewRequestDialogIsOpen } from "../../../../store/new-request-form.slice";
import { useAuthentication } from "../../../auth/AuthProvider";
import { useProductSubscriptions } from "../../AppShell";

interface CreateMenuProps {
  onClick?: () => void;
  isMobile?: boolean;
  mediaQueries: boolean[];
}

export const CreateMenu: FC<CreateMenuProps> = ({ onClick, mediaQueries }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isSmall, , isLarge = false] = mediaQueries;
  const { hasEvents, hasRequests, hasInventory } = useProductSubscriptions();
  const {
    isProductAdmin,
    isProductStandard,
    isEventAdmin,
    isEventStandard,
    isInventoryStandard,
    isInventoryAdmin,
  } = useAuthentication();

  const { allowInternalUserSubmitEvents } = useSelector(
    (state: RootState) => state.eventSettingsSlice
  );

  const { isContractorOnly, policyMap } = useSelector(
    (state: RootState) => state.localCache
  );

  const handleNewRequestClick = useCallback(() => {
    onClick && onClick();
    dispatch(setNewRequestDialogIsOpen(true));
  }, [dispatch, onClick]);

  const handleNewEventClick = useCallback(() => {
    dispatch(
      setIsOpenEventModal({
        isOpen: true,
        workingEventId: "new",
        modalContent: "form",
      })
    );
  }, [dispatch]);

  const handleNewInventoryRequestClick = useCallback(() => {
    navigate("/inventory/new-request");
  }, [navigate]);

  const canCreateRequest = useMemo(
    () =>
      Object.keys(policyMap).length > 0 &&
      hasRequests &&
      (isProductAdmin || isProductStandard),
    [hasRequests, isProductAdmin, isProductStandard, policyMap]
  );

  const canCreateEvent = useMemo(
    () =>
      hasEvents &&
      (allowInternalUserSubmitEvents || isEventAdmin || isEventStandard),
    [allowInternalUserSubmitEvents, hasEvents, isEventAdmin, isEventStandard]
  );

  const canCreateInventory = useMemo(
    () => hasInventory && (isInventoryStandard || isInventoryAdmin),
    [hasInventory, isInventoryStandard, isInventoryAdmin]
  );

  const hasMoreThanOneProduct = useMemo(() => {
    let result = 0;
    canCreateEvent && result++;
    canCreateRequest && result++;
    canCreateInventory && result++;

    return result;
  }, [canCreateEvent, canCreateRequest, canCreateInventory]);

  if (hasMoreThanOneProduct === 0 || isContractorOnly) return null;

  if (hasMoreThanOneProduct === 1 && !isSmall)
    return (
      <>
        {canCreateRequest && (
          <Button size="sm" colorScheme="blue" onClick={handleNewRequestClick}>
            New Request
          </Button>
        )}

        {canCreateEvent && (
          <Button size="sm" colorScheme="blue" onClick={handleNewEventClick}>
            New Event
          </Button>
        )}

        {canCreateInventory && (
          <Button
            size="sm"
            colorScheme="blue"
            onClick={handleNewInventoryRequestClick}
          >
            New Inventory
          </Button>
        )}
      </>
    );
  const menuItems = [];

  if (canCreateRequest) {
    menuItems.push({
      onClick: handleNewRequestClick,
      text: "New Request",
    });
  }
  if (canCreateEvent) {
    menuItems.push({
      onClick: handleNewEventClick,
      text: "New Event",
    });
  }
  if (canCreateInventory) {
    menuItems.push({
      onClick: handleNewInventoryRequestClick,
      text: "Inventory Request",
    });
  }

  return (
    <Menu>
      <MenuButton
        p={0}
        display="flex"
        flexDir="row"
        minW="max-content"
        _hover={{ backgroundColor: "none" }}
      >
        {isLarge && (
          <Button
            mr={0.5}
            size="sm"
            as="span"
            colorScheme="blue"
            borderEndEndRadius={0}
            borderTopEndRadius={0}
          >
            Create
          </Button>
        )}
        <IconButton
          size="sm"
          as="span"
          colorScheme="blue"
          borderTopStartRadius={isLarge ? 0 : 6}
          borderBottomStartRadius={isLarge ? 0 : 6}
          aria-label="Search database"
          icon={isLarge ? <ChevronDownIcon /> : <SmallAddIcon />}
        />
      </MenuButton>
      <MenuList zIndex={5}>
        {menuItems.map((item, index) => (
          <Fragment key={`${item.text}`}>
            <MenuItem onClick={item.onClick}>{item.text}</MenuItem>
            {index < menuItems.length - 1 && <MenuDivider m={1} />}
          </Fragment>
        ))}
      </MenuList>
    </Menu>
  );
};
